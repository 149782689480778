
import { computed, onMounted, ref, watch } from 'vue';
import { cloneProject, deleteProject } from '@/api/rest'
import {
    selectedDataset,
    loadingState,
    allProjectsForDataset,
    selectedProject,
    editingProject,
    selectProject,
    selectedDataObjects,
    getAllDatasets,
    loadDataset,
    loadProjectsForDataset,
    sortAscending,
    sortOption,
    filters,
} from '@/store';
import ProjectForm from '@/components/ProjectForm.vue';
import { Project } from '@/types';
import router from '@/router';

export default {
    components: { ProjectForm },
    props: {
        dataset: {
            type: Number,
            required: true
        },
        searchText: {
            type: String,
            required: false
        }
    },
    setup(props) {
        const deleting = ref();

        const sortedProjects = computed(() => {
            if (allProjectsForDataset.value) {
                let projects = allProjectsForDataset.value.sort((a, b) => {
                    let sortA = a[sortOption.value];
                    let sortB = b[sortOption.value];

                    if ((!sortA || !sortB)) {
                        return 0;
                    }

                    if (sortA < sortB) {
                        return (sortAscending.value === true) ? -1 : 1;
                    }
                    if (sortA > sortB) {
                        return (sortAscending.value === true) ? 1 : -1;
                    }
                    return 0;
                });

                projects = projects.filter((p) => {
                    if (filters.value.private && filters.value.readonly) {
                        return !p.private && !p.readonly;
                    }
                    if (filters.value.private) {
                        return !p.private;
                    }
                    if (filters.value.readonly) {
                        return !p.readonly;
                    }

                    return true;
                })

                return projects;
            }
            return [];
        });

        async function selectOrDeselectProject (project: Project) {
            if(!selectedProject.value){
                selectProject(project.id);
                router.push(`/dataset/${props.dataset}/project/${project.id}`);
            } else {
                selectedProject.value = undefined;
                selectedDataObjects.value = [];
                await getAllDatasets();
            }
        }

        function cloneProj(project: Project) {
            loadingState.value = true
            cloneProject(project.id)
            .then(() => {
                loadProjectsForDataset(props.dataset);
                loadingState.value = false;
            })
        }

        function deleteProj() {
            loadingState.value = true
            deleteProject(deleting.value.id).then(
                () => {
                    deleting.value = undefined
                    loadProjectsForDataset(props.dataset);
                    if(selectedDataset.value){
                        selectedProject.value = undefined;
                    }
                    loadingState.value = false
                }
            )
        }

        function back() {
            selectedDataset.value = undefined;
            router.push("/");
        }

        function updateSelectedDataset() {
            if (selectedDataset.value === undefined
            || selectedDataset.value.id !== props.dataset
            ) {
                loadDataset(props.dataset);
                loadProjectsForDataset(props.dataset);
            }
        }

        onMounted(updateSelectedDataset);

        watch(() => props.dataset, updateSelectedDataset);
        watch(() => props.searchText, () => loadProjectsForDataset(props.dataset));


        return {
            selectedDataset,
            allProjectsForDataset,
            selectedProject,
            selectOrDeselectProject,
            cloneProj,
            deleting,
            deleteProj,
            editingProject,
            selectProject,
            back,
            loadingState,
            sortedProjects,
        }
    }
}
