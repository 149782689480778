
import {
    analysis,
    cacheAllComparisons,
    analysisFilesShown,
    currentAnalysisParticlesFiles,
    meanAnalysisParticlesFiles
} from '@/store';
import { AnalysisGroup } from '@/types';
import { Ref, computed, inject, ref, watch, onMounted } from 'vue';

import { AnalysisTabs } from './util';

  export default {
    props: {
      currentTab: String,
      openTab: Number,
    },
    setup(props) {
      const currGroup = ref<AnalysisGroup[]>();
      const groupRatio = ref(0.5);
      const groupDiff = ref(false);
      const groupSet = ref<string>();
      const currPairing = ref<{left: string, right: string}>({left:"", right:""});
      const prevPairing = ref<{left: string, right: string}>({left:"", right:""}); // stores the previously selected pairing

      const currentlyCaching: Ref | undefined = inject('currentlyCaching');
      const animate = ref<boolean>(false);

      let step = 0.1;
      let intervalId: number;

      const methods = {
        // determine if the currently selected group pairing is inverted
        // returns true if they are inverted, false if not
        pairingIsInverted(g: any) {
            return (g.group1 === currPairing.value.right && g.group2 === currPairing.value.left)
        },
        updateCurrGroup() {
            currGroup.value = analysis.value?.groups.filter((g) => {
                if (g.name === groupSet.value) {
                    if ((g.group1 === currPairing.value.left && g.group2 === currPairing.value.right)
                        || (g.group1 === currPairing.value.right && g.group2 === currPairing.value.left)) { // if the groups pairings match the selected pairings
                        if (!methods.pairingIsInverted(g))  { // in-order group pairings
                            if (g.ratio === groupRatio.value) {
                                return true;
                            }
                        } else if (methods.pairingIsInverted(g)) { // inverted group pairings
                            if (g.ratio === parseFloat((1 - groupRatio.value).toFixed(1))) { // floating point precision errors (https://en.wikipedia.org/wiki/Floating-point_arithmetic#Accuracy_problems)
                                return true;
                            }
                        }
                    }
                }
            })
        },
        setDefaultPairing() {
            if (groupSet.value === undefined && allGroupSets.value?.length) {
                groupSet.value = allGroupSets.value[0];
            }
            // default left and right group selections: first and second item in groupSet pairings list
            if (groupSet.value !== undefined) {
                currPairing.value.left = groupPairings.value[groupSet.value][0];
                currPairing.value.right = groupPairings.value[groupSet.value][1];
                prevPairing.value = {left: currPairing.value.left, right: currPairing.value.right};
            }
        },
        // triggered on group pairing select change
        updateGroupSelections() {
            // if the new left and right groups are the same, flip the two values according to the previous selections
            if (currPairing.value.left === currPairing.value.right && groupSet.value !== undefined) {
                // set new non-changed side to opposite side in old pairing
                if (currPairing.value.left === prevPairing.value.left) {
                    currPairing.value.left = prevPairing.value.right;
                }
                if (currPairing.value.right === prevPairing.value.right) {
                    currPairing.value.right = prevPairing.value.left;
                }
            }

            prevPairing.value.left = currPairing.value.left;
            prevPairing.value.right = currPairing.value.right;

            methods.updateGroupFileShown();
        },
        updateGroupFileShown() {
            let filesShown: string[] | undefined = undefined;
            let particles: string[] | undefined = undefined;
            methods.updateCurrGroup();

            if (props.currentTab === 'analyze' && analysis.value && currGroup.value){
                filesShown = currGroup.value.map((g) => g.file);
                particles = currGroup.value.map((g) => g.particles);
            }
            currentAnalysisParticlesFiles.value = particles;
            if (groupDiff.value) {
                const meanParticles = analysis.value?.groups.filter((g) => {
                    if (g.name === groupSet.value) {
                      if (!methods.pairingIsInverted(g)) {
                        if (g.ratio === 1.0) return true;
                      } else if (methods.pairingIsInverted(g)) {
                        if (g.ratio === 0.0) return true;
                      }
                    }
                }) // account for inverted-pairings
                if (meanParticles) {
                    meanAnalysisParticlesFiles.value = meanParticles.map((p) => p.particles)
                }
            } else {
                meanAnalysisParticlesFiles.value = analysis.value?.mean_shapes.map((m) => m.particles)
            }

            analysisFilesShown.value = filesShown;
        },
        async cacheAllGroupComparisons() {
            const allInPairing = analysis.value?.groups.filter((g) => {
                if (g.name === groupSet.value) { // if groupSet is same
                    if ((g.group1 === currPairing.value.left || g.group1 === currPairing.value.right) && (g.group2 === currPairing.value.left || g.group2 === currPairing.value.right))
                        return true;
                }
            })

            if (allInPairing !== undefined) {
                await cacheAllComparisons([allInPairing]);
            }
        },
        animateSlider() {
            if (props.openTab === AnalysisTabs.Groups) { // Group tab animate
                if (groupRatio.value === 0) step = 0.1;
                if (groupRatio.value === 1) step = -0.1;
                groupRatio.value = parseFloat((groupRatio.value + step).toFixed(1));
            }
        },
        async triggerAnimate() {
            if (groupSet.value === undefined || animate == undefined) return;

            if (animate.value && currentlyCaching) {
                currentlyCaching.value = true;
                await methods.cacheAllGroupComparisons();
                currentlyCaching.value = false;
                intervalId = setInterval(methods.animateSlider, 500);
            }
            if (animate.value === false && intervalId) {
                clearInterval(intervalId);
            }
        },
        stopAnimating() {
          animate.value = false;
        }
      };

      const allGroupSets = computed(() => {
          return analysis.value?.groups.map((g) => g.name);
      })

      // get all possible unique group pairings
      const groupPairings = computed(() => {
            const g: { [name: string]: string[] } = {};
            analysis.value?.groups.map((group: AnalysisGroup) => {
                if (g[group.name] === undefined) g[group.name] = [];
                if (!g[group.name].includes(group.group1)) g[group.name].push(group.group1);
                if (!g[group.name].includes(group.group2)) g[group.name].push(group.group2);
            })
            return g;
        })

        onMounted(methods.setDefaultPairing);
        watch(groupSet, () => {
            methods.setDefaultPairing();
            methods.updateGroupFileShown();
            methods.stopAnimating();
        })
        watch(currPairing.value, () => {
            methods.updateGroupSelections();
            methods.stopAnimating();
        })
        watch(groupRatio, methods.updateGroupFileShown)
        watch(groupDiff, methods.updateGroupFileShown)

        if (animate) {
            watch(animate, methods.triggerAnimate)
        }

      return {
        methods,
        groupRatio,
        groupDiff,
        groupSet,
        allGroupSets,
        groupPairings,
        currGroup,
        currPairing,
        animate,
        currentlyCaching,
      };
    },
  };
