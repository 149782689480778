
import {
    analysis,
    analysisExpandedTab,
    jobAlreadyDone,
} from '@/store'
import { ref, watch, provide } from 'vue'
import Charts from './Charts.vue'
import Groups from './Groups.vue'
import Particles from './Particles.vue'
import PCA from './PCA.vue'
import { AnalysisTabs } from './util';
import TaskInfo from '../TaskInfo.vue'
import { AnalysisParams } from '@/types'

export default {
    props: {
        currentTab: {
            type: String,
            required: true,
        }
    },
    components: {
        Charts,
        Groups,
        Particles,
        PCA,
        TaskInfo,
    },
    setup() {
        const formDefaults: AnalysisParams = {range: 2, steps: 11}
        const openTab = ref(AnalysisTabs.PCA);
        const formData = ref<AnalysisParams>({...formDefaults});
        const currentlyCaching = ref<boolean>(false);

        const pca = ref();
        const groups = ref();

        // provide mutable ref for use in child components
        provide('currentlyCaching', currentlyCaching);

        watch(openTab, () => {
            analysisExpandedTab.value = openTab.value;
            switch(openTab.value) {
                case AnalysisTabs.PCA:
                    if (groups.value) {
                        groups.value.methods.stopAnimating();
                    }
                    pca.value.methods.updateFileShown();
                    break;
                case AnalysisTabs.Groups:
                    pca.value.methods.stopAnimating();
                    if (groups.value) {
                        if (groups.value.currGroup) {
                            groups.value.methods.updateGroupFileShown();
                        }
                    }
                    break;
                case AnalysisTabs.Charts:
                    if (groups.value) {
                        groups.value.methods.stopAnimating();
                    }
                    pca.value.methods.stopAnimating();
                    break;
                case AnalysisTabs.Particles:
                    if (groups.value) {
                        groups.value.methods.stopAnimating();
                    }
                    pca.value.methods.stopAnimating();
                    break;
            }
        })

        function resetForm() {
            formData.value = formDefaults
        }

        return {
            analysis,
            formData,
            openTab,
            currentlyCaching,
            pca,
            groups,
            resetForm,
            jobAlreadyDone
        }
    },
}
