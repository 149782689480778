
import { ref } from 'vue'
import router from '@/router';
import { selectedDataset, selectedProject } from '@/store';

export default {
    setup() {
      const searchText = ref(router.currentRoute.params.searchText)

      async function navigateToResults() {
        // use store functions
        let targetUrl = "/"
        if (selectedDataset.value) {
            targetUrl = `/dataset/${selectedDataset.value.id}/`
        }

        if (searchText.value) {
            targetUrl = targetUrl + `search/${searchText.value}`
        }

        router.push(targetUrl);
      }

      router.beforeEach(async (to, from, next) => {
        if (!to.params.searchText) searchText.value = ''
        else searchText.value = to.params.searchText;
        next()
      })

      return {
        searchText,
        selectedDataset,
        selectedProject,
        navigateToResults
      }
    },
}
