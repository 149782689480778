
import _ from 'lodash';
import {
  goodBadAngles,
  showGoodBadParticlesMode,
  goodBadMaxAngle,
} from '@/store';

export default {
    name: "Particles",
    setup() {

      function changeMaxAngle(angle: string) {
        if (parseFloat(angle)) {
          goodBadMaxAngle.value = parseFloat(angle);
        }
      }

      return {
        changeMaxAngle: _.debounce(changeMaxAngle, 1000),
        showGoodBadParticlesMode,
        goodBadAngles,
        goodBadMaxAngle,
      };
    },
};
