
import { computed, onMounted, ref, watch } from 'vue';
import {
    allDatasets,
    selectedDataset,
    loadingState,
    selectedDataObjects,
    loadProjectsForDataset,
    getAllDatasets,
sortOption,
sortAscending,
} from '@/store';
import { Dataset } from '@/types';
import SubsetSelection from '@/components/SubsetSelection.vue';
import router from '@/router';

export default {
  components: { SubsetSelection },
  props: {
    searchText: {
        type: String,
        required: false
    }
  },
    setup(props) {
        const selectingSubsetOf = ref();
        selectedDataset.value = undefined;

        const sortedDatasets = computed(() => {
            if (allDatasets.value) {
                const datasets = allDatasets.value.sort((a, b) => {
                    let sortA = a[sortOption.value];
                    let sortB = b[sortOption.value];

                    if ((!sortA || !sortB)) {
                        return 0;
                    }

                    if (sortA < sortB) {
                        return (sortAscending.value === true) ? -1 : 1;
                    }
                    if (sortA > sortB) {
                        return (sortAscending.value === true) ? 1 : -1;
                    }
                    return 0;
                });

                return datasets;
            }
            return [];
        });

        async function selectOrDeselectDataset (dataset: Dataset | undefined) {
            if(!selectedDataset.value && dataset) {
                selectedDataset.value = dataset;

                router.push("/dataset/"+dataset.id);
                loadProjectsForDataset(dataset.id);
            } else {
                selectedDataset.value = undefined;
                selectedDataObjects.value = [];
                await getAllDatasets();
            }
        }

        onMounted(async () => {
            await getAllDatasets();
            if(selectedDataset.value){
                const datasetId = selectedDataset.value.id;
                // reset selectedDataset to maintain updates from latest fetch of all datasets
                selectedDataset.value = allDatasets.value.find(
                    (d) => d.id == datasetId
                )
            }
        })

        watch(() => props, () => {
            getAllDatasets();
            selectedDataset.value = undefined;
        }, {deep:true});

        return {
            allDatasets,
            selectedDataset,
            selectOrDeselectDataset,
            selectingSubsetOf,
            loadingState,
            sortedDatasets,
        }
    }
}
