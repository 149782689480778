
import { computed, ref, watch } from 'vue';
import {
    selectedProject,
    currentTasks,
    jobAlreadyDone,
    fetchJobResults,
    spawnJob,
    abort,
} from '@/store';

export default {
  props: {
    taskName: {
        type: String,
        required: true
    },
    formData: {
        type: Object,
        required: false,
    },
    formDefaults: {
        type: Object,
        required: false,
    },
    disabled: {
        type: Boolean,
        required: false,
    },
  },
  setup(props, { emit }) {
    const showSubmissionConfirmation = ref(false);
    const showAbortConfirmation = ref(false);
    const alreadyDone = ref(jobAlreadyDone(props.taskName))

    const taskData = computed(
        () => {
            if(!selectedProject.value?.id ||
            !currentTasks.value[selectedProject.value.id]) return undefined
            return currentTasks.value[selectedProject.value.id][props.taskName]
        }
    )

    watch(taskData, async (value, oldValue) => {
        if (value === undefined && oldValue !== undefined) {
            await fetchJobResults(props.taskName)
            alreadyDone.value = jobAlreadyDone(props.taskName)
        }
    })

    const submitDisabled = computed(() => props.disabled ? props.disabled : !!taskData.value)

    const resetDisabled = computed(() => !!props.disabled)

    function resetForm() {
        emit('resetForm')
    }

    async function submitForm(_: Event | undefined, confirmed=false){
        if (!selectedProject.value) return
        if(alreadyDone.value && !confirmed){
            showSubmissionConfirmation.value = true
            return
        }
        await spawnJob(props.taskName, props.formData || {})
    }

    return {
        props,
        alreadyDone,
        showSubmissionConfirmation,
        showAbortConfirmation,
        submitDisabled,
        taskData,
        resetForm,
        submitForm,
        abort,
        resetDisabled,
    }
  }
}
