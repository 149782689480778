
import { DataObject, Dataset } from '@/types'
import { ref } from 'vue'
import DataList from './DataList.vue'
import { selectedDataObjects, allDatasets } from '@/store';
import { subsetDataset } from '@/api/rest';


export default {
    components: {
        DataList
    },
    props: {
        targetDataset: {
            type: Object as () => Dataset,
            required: true,
        },
    },
    setup(props, context) {
        const name = ref('')
        const description = ref('')
        const keywords = ref('')

        async function submitForm() {
            const selected = selectedDataObjects.value.map(
                (dataObject: DataObject) => ({
                    id: dataObject.id,
                    subject: dataObject.subject,
                    type: dataObject.type,
                })
            )
            const newDataset = await subsetDataset(
                props.targetDataset.id,
                {
                    name: name.value,
                    description: description.value,
                    keywords: keywords.value,
                    selected,
                }
            )
            if(newDataset){
                allDatasets.value.push(newDataset)
                context.emit('close')
            }
        }

        return {
            name,
            description,
            keywords,
            submitForm,
            selectedDataObjects,
        }
    }
}
