
/* eslint-disable no-unused-vars */
import {
    selectedProject,
    abort,
    deepSSMDataTab,
    deepSSMResult,
    deepSSMAugDataShown,
    groomFormData,
    optimizationFormData,
    loadDeepSSMDataForProject,
    deepSSMLoadingData,
    deepSSMErrorGlobalRange,
} from '@/store';
import { Ref, onMounted, ref, watch } from 'vue';
import { parseCSVFromURL } from '@/helper';
import TaskInfo from './TaskInfo.vue';


export default {
  components: { TaskInfo },
    setup() {
        enum Sampler {
            Gaussian = "Gaussian",
            Mixture = "mixture",
            KDE = "KDE",
        }

        enum LossFunction {
            MSE = "MSE",
            Focal = "Focal",
        }

        const openExpansionPanel = ref<number>(0);
        const controlsTabs = ref();
        const showAbortConfirmation = ref(false);
        const formData = ref();
        const formDefaults = ref({});

        const prepData = {
            testing_split: ref<number>(20),
            validation_split: ref<number>(20),
            percent_variability: ref<number>(95),
            image_spacing: ref<{x: number, y: number, z: number}>({x: 1, y: 1, z: 1})
        }

        // Augmentation
        const augmentationData = {
            aug_num_samples: ref<number>(300),
            aug_sampler_type : ref<Sampler>(Sampler.Gaussian),
        }

        // Training
        const trainingData = {
            train_loss_function: ref<LossFunction>(LossFunction.MSE),
            train_epochs: ref<number>(100),
            train_learning_rate: ref<number>(0.001),
            train_batch_size: ref<number>(8),
            train_decay_learning_rate: ref<boolean>(true),
            train_fine_tuning: ref<boolean>(true),
            train_fine_tuning_epochs: ref<number>(100),
            train_fine_tuning_learning_rate: ref<number>(0.001),
        }

        // headers are assigned from the parseCSV function's output
        const dataTables = {
            aug_table: ref<any>(undefined),
            aug_headers: ref<any>(undefined),
            training_table: ref<any>(undefined),
            training_headers: ref<any>([
                {text: "Training Stage", value: '0'},
                {text: "Epoch", value: '1'},
                {text: "LR", value: '2'},
                {text: "Train_Err", value: '3'},
                {text: "Train_Rel_Err", value: '4'},
                {text: "Val_Err", value: '5'},
                {text: "Val_Rel_Err", value: '6'},
                {text: "Sec", value: '7'},
            ]),
            testing_table: ref<any>(undefined),
            testing_headers: ref<any>([{text: "Name", value: '0'}, {text: "Distance", value: '1'}]),
        }

        /**
         * Converts an object of reactive properties to a plain object for use in api formData fields.
         */
        function getFormSection(object: {[key: string]: Ref<any>}) {
            return (
                Object.entries(object)
                    .map(([key, value]) => [key, value.value])
                    .reduce((obj, [key, value]) => {
                        obj[key] = value;
                        return obj;
                    }, {} as any)
            )
        }

        function getFormData() {
            const prepFormData = getFormSection(prepData);
            const augFormData = getFormSection(augmentationData);
            const trainFormData = getFormSection(trainingData);

            return {
                ...prepFormData,
                ...augFormData,
                ...trainFormData,
                ...groomFormData.value,
                ...optimizationFormData.value,
            }
        }

        function resetForm() {
            formData.value = formDefaults.value
            Object.entries(formData.value).forEach(([key, value]) => {
                if (prepData[key]) prepData[key].value = value
                if (augmentationData[key]) augmentationData[key].value = value
                if (trainingData[key]) trainingData[key].value = value
            })
        }

        function overwriteFormDefaultsFromProjectFile() {
            const file_contents = selectedProject.value?.file_contents
            if (file_contents) {
                const section = file_contents['deepssm']
                formDefaults.value = Object.fromEntries(
                    Object.entries(formDefaults.value).map(([key, value]) => {
                        if (section[key]) value = section[key]
                        if (value === "True") value = true
                        else if (value === "False") value = false
                        else if (typeof value === 'string' && !isNaN(parseFloat(value))) value = parseFloat(value)
                        return [key, value]
                    })
                )
            }
        }

        async function getCSVDataFromURL(url: string) {
            return await parseCSVFromURL(url);
        }

        onMounted(async () => {
            formDefaults.value = getFormData()
            overwriteFormDefaultsFromProjectFile()
            resetForm()
            if (!deepSSMResult.value && selectedProject.value) {
                deepSSMLoadingData.value = true;
                await loadDeepSSMDataForProject();
                deepSSMLoadingData.value = false;
            }
            if (deepSSMResult.value && deepSSMResult.value.result) {
                try {
                    Promise.all([
                        await getCSVDataFromURL(deepSSMResult.value.result.aug_total_data),
                        await getCSVDataFromURL(deepSSMResult.value.result.training_data_table),
                        await getCSVDataFromURL(deepSSMResult.value.result.testing_distances),
                    ]).then((res) => {
                        // get only the filename rather than the full path
                        const aug_values = res[0].map((row: any) => {
                            const newRow = {};
                            Object.keys(row).forEach((key: string) => {
                                const value = row[key];
                                if (typeof value === 'string') {
                                    newRow[key] = value.split('/').pop();
                                } else {
                                    newRow[key] = value;
                                }
                            });
                            return newRow;
                        });

                        dataTables.aug_table.value = aug_values;
                        dataTables.training_table.value = res[1];
                        dataTables.testing_table.value = res[2];

                        // Augmentation data table doesn't have headers, only a numbered list
                        dataTables.aug_headers.value = Object.keys(dataTables.aug_table.value[0]).map((_: string, index: number) => {
                            return {text: `${index+1}`, value: `${index}`}
                        });
                    });
                }
                catch (e) {
                    console.error(e);
                }
            }
        });

        watch(openExpansionPanel, () => {
            if (openExpansionPanel.value === 1 && deepSSMDataTab.value === -1) {
                deepSSMDataTab.value = 0;
            }
        });

        watch(deepSSMDataTab, () => {
            deepSSMErrorGlobalRange.value = [0, 1];
        });

        watch([
            augmentationData.aug_num_samples,
            augmentationData.aug_sampler_type,
            trainingData.train_loss_function,
            trainingData.train_epochs,
            trainingData.train_learning_rate,
            trainingData.train_batch_size,
            trainingData.train_decay_learning_rate,
            trainingData.train_fine_tuning,
            trainingData.train_fine_tuning_epochs,
            trainingData.train_fine_tuning_learning_rate,
         ], () => {
            formData.value = getFormData();
        })

        return {
            selectedProject,
            openExpansionPanel,
            controlsTabs,
            prepData,
            formData,
            resetForm,
            augmentationData,
            trainingData,
            Sampler,
            LossFunction,
            abort,
            showAbortConfirmation,
            deepSSMDataTab,
            deepSSMResult,
            deepSSMAugDataShown,
            deepSSMLoadingData,
            dataTables,
        }
    },
}
