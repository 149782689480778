
import { computed } from 'vue'
import { logout, oauthClient } from '@/api/auth';
import { allDatasets, loadingState, sortOption, sortAscending, selectedDataset, selectedProject, filters, SORT_OPTION } from '@/store';
import FilterSearch from './FilterSearch.vue';
import router from '@/router';
import { getDatasets } from '@/api/rest';


export default {
    components: {
      FilterSearch
    },
    setup() {
      const params = computed(() => ({
        dataset: selectedDataset.value?.id,
        project: selectedProject.value?.id
      }))

      const ascendingLabel = computed(() => {
        return sortOption.value !== 'modified' ? "A to Z" : "Oldest"
      })

      async function logInOrOut() {
          if (oauthClient.isLoggedIn) {
            await logout();
            window.location.reload();
          } else {
            oauthClient.redirectToLogin();
          }
      }

      async function navigateToHome() {
        selectedDataset.value = undefined
        selectedProject.value = undefined
        if (router.currentRoute.path !== '/') {
          router.push('/')
        }
        loadingState.value = true;
        allDatasets.value = (await getDatasets(undefined)).sort((a, b) => {
            if(a.created < b.created) return 1;
            if(a.created > b.created) return -1;
            return 0;
        });
        loadingState.value = false;
      }

      return {
          oauthClient,
          params,
          logInOrOut,
          selectedDataset,
          selectedProject,
          navigateToHome,
          sortOption,
          sortAscending,
          ascendingLabel,
          filters,
          SORT_OPTION,
          router,
      }
    }
}
